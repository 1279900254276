
import Vue from "vue";

import MyEditor from "@/components/notice/MyEditor.vue";

import http from "@/http";

import axios from "axios";

import { Notice, Breadcrumb, Snackbar } from "@/interfaces";

import { mdiAttachment } from "@mdi/js";

export interface DataType {
  notice?: Notice;
  attachments: File[];
}

export default Vue.extend({
  components: {
    MyEditor
  },
  data(): DataType {
    return {
      notice: undefined,
      attachments: []
    };
  },
  computed: {
    icon() {
      return { mdiAttachment };
    },
    breadcrumbs(): Breadcrumb[] {
      return [
        {
          text: "ホーム",
          to: "/"
        },
        {
          text: "お知らせ",
          to: "/notices",
          exact: true
        },
        {
          text: "作成"
        }
      ];
    }
  },
  mounted() {
    const target = this.$route.query.target === "owner" ? "owner" : "member";

    this.notice = {
      id: 0,
      title: "",
      content: "",
      status: "publish",
      attachments: [],
      created_at: "",
      updated_at: "",
      is_notify: true,
      target
    };
  },
  methods: {
    async save(i: Notice) {
      const message = `${
        this.notice?.target === "owner" ? "オーナー" : "入居者"
      }全体が対象です。保存しますか?`;
      if (!window.confirm(message)) {
        return;
      }

      try {
        const { data } = await http.post<Notice>("notices", {
          ...this.notice,
          ...i
        });

        const id = data.id;

        const url = `notices/${id}/attachments`;

        for (const attachment of this.attachments) {
          const formData = new FormData();

          formData.append("file", attachment);

          await http.post<Notice>(url, formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
        }

        const target =
          this.$route.query.target === "owner" ? "owner" : "member";

        await this.$router.push(`/notices/${target}`);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.code == "403") {
            const snackbar: Snackbar = {
              show: true,
              content: "権限がありません",
              color: "error"
            };

            this.$store.commit("setSnackbar", snackbar);
          }
        }
      }
    }
  }
});
